import React from "react"

import SEO from "../components/utils/SEO"
import HomePage from "../components/Home"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <HomePage />
  </>
)

export default IndexPage
