import React from "react"
import styled from "styled-components"

import hero from "../../images/hero.jpg"
import logo from "../../images/logo.png"

const Container = styled.section`
  min-height: 100vh;
  display: grid;
  grid-template: auto auto / 1fr;
  padding: 30px;
  @media (min-width: 768px) {
    align-items: center;
    align-content: center;
    grid-template: auto / 1fr 1fr;
  }
`

const Hero = styled.img`
  height: calc(50vh);
  width: 100%;
  object-fit: cover;
  @media (min-width: 768px) {
    height: calc(100vh - 60px);
  }
`

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    height: auto;
    display: block;
    margin-bottom: 3rem;
  }
  h1 {
    font-size: 1.5rem;
  }
`

const HomePage = () => {
  return (
    <Container>
      <Hero src={hero} alt="" />
      <Intro>
        <img src={logo} alt="" />
        <h1> Coming soon </h1>
      </Intro>
    </Container>
  )
}

export default HomePage
